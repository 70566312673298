import React from 'react';
import { Link } from 'react-router-dom';
import {Button} from '../component/Button'
import ShowImage from './Pages/ShowImage';


const Card = ({
    gallery,
    showViewGalleryButton = true,
    }) => {

    const showViewButton = showViewGalleryButton => {
        return (
            showViewGalleryButton && (
            <Link to={`/gallery/${gallery._id}`} className="">
            <Button buttonSize='btn--wide' buttonColor='primary'>
                View
            </Button>
            </Link>
        )
        );
    };

    return (
            <div className="container-fluid event-top-section lightBg">
                <div className="container">
                    <div className='event__section'>
                        <div className='event__wrapper'>
                            <div className='event__container'>
                                <div className='event__container-card'>
                                    <div className='event__container-cardInfo'>
                                        <div className='event__img-wrapper'>
                                        <ShowImage item={gallery} url="gallery" />
                                        </div>
                                        <p className="name">{gallery.name}</p>
                                        {showViewButton(showViewGalleryButton)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Card;