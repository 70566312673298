import React from 'react';
import './Aim.css';

const Aim = () => {
    return (
        <div className="container">
                <div className="mt-5 pt-5">
                    <h3>Our Aim</h3>
                    <div className="grid1">
                        <div className="grid1__item">
                            <span class="grid1__icon"><i class="fas fa-university"></i></span>
                            <h4>EMPOWERMENT</h4>
                            <p>Since the begining, the foundation has made 100% effort, to directly empower beneficiaries via sport.</p>
                        </div>
                        <div className="grid1__item">
                            <span class="grid1__icon"><i class="fas fa-running"></i></span>
                            <h4>ATHLETICS</h4>
                            <p>We are focusing on track and field as a tool, that will help us to empower school kids to be the best they can.</p>
                        </div>
                        <div className="grid1__item">
                            <span class="grid1__icon"><i class="fas fa-toolbox"></i></span>
                            <h4>INFRASTRUCTURE</h4>
                            <p>In the next few years, we look forward to infrastructure growth. This will ensure that we meet the athletics training needs of these youngsters</p>
                        </div>
                    </div>
                </div><br/>
        </div>
    )
}


export default Aim;