import React from "react";
import { API } from "../../config";

const ShowImage = ({ item, url }) => (

        <img
            src={`${API}/${url}/photo/${item._id}`}
            alt={item.name}
            className="mb-3 home__hero-img"
            style={{ maxHeight: "180px", maxWidth: "100%" }}
        />

);

export default ShowImage;