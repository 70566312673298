import React from 'react';
import Layout from './Layout';
import { Helmet } from 'react-helmet';
import './Home.css';
import Events from './Pages/Events';
import HomePage from './Pages/HomePage';
import Footer from './Pages/Footer';
import Aim from './Pages/Aim';
import ImageGallery from './Pages/ImageGallery';


const Home = () => {
    const head = () => (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Christy Ihunaegbo Foundation</title>
            <meta name="description" content="Foundation website for Christy Ihunaegbo Foundation" />
            <meta name="description" content="Christy Ihunaegbo" />
            <link rel="canonical" href="https://christyihunaegbo.org" />
        </Helmet>
    );
    
    return (
        <div className="">
            <Layout>
            {head()}
                <HomePage/>
                <Aim/>
                <ImageGallery/>
                <Events/>
                <Footer/>
            </Layout>

        </div>
    )
}

export default Home;