import React from 'react';
import Menu from './Menu';
import "./Layout.css";


const Layout = ({
    children
}) => ( 
    <div>
        <Menu />
        <div className="">{children}</div>
    </div>
);

export default Layout;