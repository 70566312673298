import React from 'react';
import founderpic from '../../img/founderPic2.jpg';
import aboutus from '../../img/aboutus.jpg';
import vpic from '../../img/raphPic.jpg';
import jpic from '../../img/jPic.jpg';
import Layout from '../Layout';
import './About.css';

const About = () => {
    return (
        <div className="">
            <Layout>
                <div className="top-img">
                    <img
                    src={aboutus}
                    alt=""
                    className="img-fluid"
                    />
                </div>
                <div className="container about__heading">
                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <h3 className="display-4">ABOUT US</h3><br/>
                            <p className='lead m-1 p-1'>
                            Christy ihunaegbo foundation is a non for profit organization providing youth empowerment and rebuilding communities through sport, our focus is services to low-income, less-privileged and resources-limited communities in Canada and Africa. 
                            </p><br/>
                            <h3>MISSION</h3><br/>
                            <p className="m-1 p-1">The mission of Christy ihunaegbo foundation is to serve various regions and areas in our immediate communities in Canada and Africa by providing integrated sport and recreational activities services that improve the quality of their lives.</p>
                    
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1">
                            <b><h3 className="mt-5">MEET THE FOUNDER</h3></b><br></br>
                                <div className="m-1 p-1">
                                    <p>The motivation behind st-Christy Ihunaegbo foundation, was the struggle I and my colleague went through during our days playing sports especially Track and field Athletes. 
                                    </p>
                                    <p>
                                    Seeing the hardships and struggle we went through, must us was unable to transport ourselves daily to practice, had no kits for practice, no food to eat, no transportation to championship, this includes me.
                                    </p>
                                    <p>
                                    Reflecting back on this journey, it's really break my heart that things has gone from bad to worst for the new generation and up coming athletes. There's are reason behind our motivated to make a difference in sport we love so much, and to target kids in street that have interest in sport but could not partake because their families could not support them.
                                    </p>
                                    <p>
                                    We will have a progressive, and safe society with limited crimes, safe for everyone, if we can all put our heads together and focus on our kids, get them involved in things that would preoccupy them physically and mentally.
                                    </p>                                
                                </div>
                            </div>
                            
                            <div className="col-sm order-1 order-lg-2 mt-5">
                                <img
                                src={founderpic}
                                alt=""
                                className="img-fluid"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1">
                            <b><h3 className="mt-5">MEET THE OFFICIALS</h3></b><br></br>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm order-1 order-lg-2 mt-5">
                                            <img
                                            src={vpic}
                                            alt=""
                                            className="img-fluid"
                                            />
                                            <h5>Raphael C Ihunaegbo</h5>
                                            <p><i>Chief Coach St.Christy Athletics Club</i></p>
                                        </div>
                                        <div className="col-sm order-1 order-lg-2 mt-5">
                                            <img
                                            src={jpic}
                                            alt=""
                                            className="img-fluid"
                                            />
                                            <h5>Giuseppina (Josephine) Kadiri</h5>
                                            <p><i>Head of Administration</i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
            </Layout>
        </div>

    )
}

export default About;
