import React from 'react';
import './App.css';
import Home from './core/Home';

const App = () => {
    return (
        <Home/>
    );
};

export default App;