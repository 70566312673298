/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { read } from './apiCore';
import CardView from './CardView';

const Gallery = props => {
    const [gallery, setGallery] = useState({});
    const [error, setError] = useState(false);

    const loadSingleGallery = galleryId => {
        read(galleryId).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setGallery(data);
            }
        });
    };

    useEffect(() => {
        const galleryId = props.match.params.galleryId;
        loadSingleGallery(galleryId);
    }, [props]);

    return (
        <Layout>
            <div className="container">
            <div className="row mt-5">
                <div className="col-lg-8 col-md-8 col-sm-0">
                    {gallery && gallery.description && <CardView gallery={gallery} showViewGalleryButton={false} />}
                </div>
            </div>
            </div>
        </Layout>
    );
};

export default Gallery;