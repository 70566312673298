import React from 'react';
import Layout from '../Layout';

const Donate = () => {
    return (
        <div classname="">
            <Layout>
                <div className="container mt-5 pt-5">
                    <h1>Donate</h1>
                    <br/>
                    <p>
                    Giving is not just about making a donation; it’s about making a difference. Donate to us and together we can become catalysts of change and a positive difference in peoples lives. If you would like to make a donation, please use Paypal or send an email to donate@christyihunaegbo.org or fill the form below; we will contact you as soon as possible to finalize the donation process.</p>
                </div>
                <div className="container mt-1 pt-1">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5">
                                <div className="container-fluid donate__container">
                                <h4 className="donate__head mb-4">Donate via Paypal</h4>
                                <p>Thank you for your decision to donate to us</p>
                                <p>please click on the donate button</p>
                                <form action="https://www.paypal.com/donate" method="post" target="_top">
                                <input type="hidden" name="hosted_button_id" value="T38VQR8H2NN4S" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_CA/i/scr/pixel.gif" width="1" height="1" />
                                </form>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 mt-5">
                            <h4 className="donate__head mb-4">Donate via other means</h4>
                            <p>Email to:</p>
                            <a href="mailto:donate@christyihunaegbo.org"><button type="submit" class="btn btn-primary">Message to donate</button></a>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>

    )
}

export default Donate;
