import React from 'react';
import Layout from '../Layout';
import './Track.css';
import YoutubeEmbed from "./YoutubeEmbed";
import founderpic from '../../img/founderPic2.jpg';
import vpic from '../../img/raphPic.jpg';
import upic from '../../img/Untitled.jpg';
import file1 from '../../files/thoroldElitePrepMeetResults.pdf';


const Track = () => {
    return (
        <Layout>
            <div class="jumbotron jumbotron-fluid">
            <div class="container">
                <h1 class="display-4">St.Christy Ihunaegbo Athletics Club</h1>
                <p class="lead">Welcome to our track and field club, powered by Christy and Raphael Ihunaegbo</p>
            </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <YoutubeEmbed embedId="QSfu44wk9Fk" />
                        <div className="meet-athletes">
                            <h3 className="mt-1 pt-1 ">Meet The Athletes</h3><hr/>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img class="card-img-top" src={upic} alt="Athlete pic"/>
                                            <div class="card-body">
                                                <h5 class="card-title">Athlete Name</h5>
                                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img class="card-img-top" src={upic} alt="Athlete pic"/>
                                            <div class="card-body">
                                                <h5 class="card-title">Athlete Name</h5>
                                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img class="card-img-top" src={upic} alt="Athlete pic"/>
                                            <div class="card-body">
                                                <h5 class="card-title">Athlete Name</h5>
                                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img class="card-img-top" src={upic} alt="Athlete pic"/>
                                            <div class="card-body">
                                                <h5 class="card-title">Athlete Name</h5>
                                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img class="card-img-top" src={upic} alt="Athlete pic"/>
                                            <div class="card-body">
                                                <h5 class="card-title">Athlete Name</h5>
                                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card">
                                            <img class="card-img-top" src={upic} alt="Athlete pic"/>
                                            <div class="card-body">
                                                <h5 class="card-title">Athlete Name</h5>
                                                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>


                        <div className="track-news">
                            <h3 className="mt-2 pt-2 ">Track meetings</h3>
                            <a href={file1} class="track-meet-button" target="_blank" rel='noreferrer'download
                            >Thorold Elite Prep Meeting</a>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-sm-2 track-officials">
                        <h3 className="mt-1 pt-1 ">Meet The Officials</h3><hr/>
                        <h5>Christy Ihunaegbo</h5>
                        <img
                                src={founderpic}
                                alt=""
                                className="img-fluid"
                                />
                        <i>(President Christy Ihunaegbo Foundation)</i>                      
                        <div className="m-1 p-1">
                            <ul>
                                <li>Bsc in Health studies(Graduated from York University)</li>
                                <li>Olympian, Athens 2004</li>
                                <li>Common wealth games medalist, Melbourne 2006</li>
                                <li>300m & 4*400m women record holder, York University</li>
                                <li>4 years Nigerian National Champion</li>
                            </ul>
                        </div>
                        <br/>
                        <h5>Raphael C Ihunaegbo</h5>
                        <img
                                src={vpic}
                                alt=""
                                className="img-fluid"
                                />
                        <i>(VP Christy Ihunaegbo Foundation)</i>                
                        <div className="m-1 p-1">
                            <ul>
                                <li>Health studies(undergraduate York University)</li>
                                <li>Chief Coach St.Christy Athletics Club</li>
                                <li>Ex-sprint athlete</li>
                            </ul>
                        </div>              
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}

export default Track;