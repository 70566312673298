import React from 'react';
import {Link} from 'react-router-dom';
import ldimg from '../../img/IMG-20210405-WA0000.jpg';
import './HomePage.css';


const HomePage = () => {
    return (
        <>
            <div className="l-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5">
                            <h1 className="heading">Christy Ihunaegbo Foundation!</h1>
                            <p></p>
                            <p>
                            Christy Ihunaegbo Foundation is a non profit organization, our mission is to take kids 
                            off the street by providing youth community empowerment through sport. 
                            </p>
                            <p>The idea behind the foundation comes from our upbringing back in Africa, there are a lot of gifted kids, even in Canada, 
                                currently roaming the street and exposing themselves to danger, most of these kids if giving the oppoortunity
                                would do great things in sport.
                            </p>
                            <hr className="my-4"/>
                            <div className="d-flex flex-column flex-lg-row">
                                <Link to='/signup' class="btn btn-light btn-primary-spacing  font-weight-bold mb-3 mb-lg-0" role="button">
                                    Volunteer
                                </Link>
                                <Link
                                className="btn btn-dark font-weight-bold ml-lg-3"
                                to="/about-us"
                                role="button"
                                >
                                Learn more
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 mt-5 ld-image">
                            <img
                            src={ldimg}
                            alt=""
                            className="img-fluid ld-image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default HomePage;
