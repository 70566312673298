import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import { getPhotoGalleries } from '../apiCore';
import Card from '../../core/Card';


const PhotoGallery = () => {
    const [galleriesByArrival, setGalleriesByArrival] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(false);

    const loadGallerisByArrival = () => {
        getPhotoGalleries('createdAt').then(data => {
            // console.log(data);
            if (data.error) {
                setError(data.error);
            } else {
                setGalleriesByArrival(data);
            }
        });
    };

    useEffect(() => {
        loadGallerisByArrival();
    }, []);

    return (
        <Layout>
            <div class="jumbotron jumbotron-fluid">
            <div class="container">
                <h1 class="display-4">Events Photo Gallery</h1>
                <p className='lead m-1 p-1'>
                    Series of various events by Christy Ihunaegbo Foundation
                </p>
            </div>
            </div>
            <div className="container">
                <h2 className="mb-4">Events Overview</h2>
                <div className="row">
                    {galleriesByArrival.map((gallery, i) => (
                        <div key={i} className="col-lg-4 col-md-4 col-sm-0 mb-3">
                            <Card gallery={gallery} />
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export default PhotoGallery;