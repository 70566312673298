/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {Link } from 'react-router-dom'
import { getGalleries } from '../apiCore';
import Card from '../../core/Card';

const ImageGallery = () => {
    const [galleriesByArrival, setGalleriesByArrival] = useState([]);
    const [error, setError] = useState(false);

    const loadGallerisByArrival = () => {
        getGalleries('createdAt').then(data => {
            // console.log(data);
            if (data.error) {
                setError(data.error);
            } else {
                setGalleriesByArrival(data);
            }
        });
    };

    useEffect(() => {
        loadGallerisByArrival();
    }, []);

    return (
            <div className="container">
                <Link to="/photo-gallery" className="text-dark"><h2 className="mb-4">Events Overview</h2></Link>
                <div className="row">
                    {galleriesByArrival.map((gallery, i) => (
                        <div key={i} className="col-lg-4 col-md-4 col-sm-0 mb-3">
                            <Card gallery={gallery} />
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default ImageGallery;