import React from 'react';
import { Link } from 'react-router-dom';
import {Button} from '../../component/Button'
import './Events.css';
import YoutubeEmbed from "./YoutubeEmbed";


const Services = () => {
    return (
        <>
            <div className="container-fluid event-top-section lightBg">
                <div className="container">
                    <div className='event__section'>
                        <div className='event__wrapper'>
                        <Link to="/video-gallery"><h3 className='event__heading'>Video Gallery</h3></Link>
                        <div className='event__container'>
                            <Link to='/video-gallery' className='event__container-card'>
                                <div className='event__container-cardInfo'>
                                    <div>
                                        <YoutubeEmbed embedId="cpkGeCtqwJo" />
                                        <Button buttonSize='btn--wide' buttonColor='primary'>
                                        View in Gallery
                                        </Button>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/video-gallery' className='event__container-card'>
                                <div className='event__container-cardInfo'>
                                    <div>
                                        <YoutubeEmbed embedId="QSfu44wk9Fk" />
                                        <Button buttonSize='btn--wide' buttonColor='primary'>
                                        View in Gallery
                                        </Button>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/video-gallery' className='event__container-card'>
                                <div className='event__container-cardInfo'>
                                    <div>
                                        <YoutubeEmbed embedId="gEAyR_wg9xM" />
                                        <Button buttonSize='btn--wide' buttonColor='primary'>
                                        View in Gallery
                                        </Button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Services;
