import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import Signup from './auth/Signup';
import Signin from './auth/Signin';
import Activate from './auth/Activate';
import Private from './core/Private';
import UserUpdate from './core/Pages/UserUpdate';
import PrivateRoute from './auth/PrivateRoute';
import Admin from './core/Admin';
import AddCategory from './admin-pages/AddCategory';
import AddGallery from './admin-pages/AddGallery';
import UpdateGallery from './admin-pages/UpdateGallery';
import AdminRoute from './auth/AdminRoute';
import Forgot from './auth/Forgot';
import Reset from './auth/Reset';
import About from './core/Pages/About';
import Donate from './core/Pages/Donate';
import Track from './core/Pages/Track';
import PhotoGallery from './core/Pages/PhotoGallery';
import VideoGallery from './core/Pages/VideoGallery';
import Gallery from './core/Gallery';
import ManageGallery from './admin-pages/ManageGallery';


const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={App} />
                <Route path="/about-us" exact component={About} />
                <Route path="/track-club" exact component={Track} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/signin" exact component={Signin} />
                <Route path="/donate" exact component={Donate} />
                <Route path="/photo-gallery" exact component={PhotoGallery} />
                <Route path="/video-gallery" exact component={VideoGallery} />
                <Route path="/auth/activate/:token" exact component={Activate} />
                <PrivateRoute path="/private" exact component={Private} />
                <PrivateRoute path="/user-update" exact component={UserUpdate} />
                <AdminRoute path="/admin" exact component={Admin} />
                <AdminRoute path="/create/category" exact component={AddCategory} />
                <AdminRoute path="/create/gallery" exact component={AddGallery} />
                <AdminRoute path="/admin/gallery/update/:galleryId" exact component={UpdateGallery} />
                <AdminRoute path="/admin/gallery" exact component={ManageGallery} />
                <Route path="/auth/password/forgot" exact component={Forgot} />
                <Route path="/auth/password/reset/:token" exact component={Reset} />
                <Route path="/gallery/:galleryId" exact component={Gallery} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;