import React from 'react';
import { Link } from 'react-router-dom';
import ShowImage from '../core/Pages/ShowImage';
import moment from 'moment';


const CardView = ({
    gallery,
    showViewGalleryButton = true,
    setRun = f => f,
    run = undefined
}) => {

    const showViewButton = showViewGalleryButton => {
        return (
        showViewGalleryButton && (
            <Link to={`/gallery/${gallery._id}`} className="mr-2">
                <button type="button" className="btn btn-outline-primary mt-2 mb-2 card-btn-1">View Product</button>
            </Link>
        )
        );
    };

    return (
        <div className="card">
        <div className="card-header card-header-1 name"><h3>{gallery.name}</h3></div>
        <div className="card-body">
            <ShowImage item={gallery} url="gallery" />
            <p className="card-p  mt-2">{gallery.description.substring(0, 1000)} </p>
            <p className="black-9">Category: {gallery.category && gallery.category.name}</p>
            <p className="black-8">Added {moment(gallery.createdAt).fromNow()}</p>
            <br />

            {showViewButton(showViewGalleryButton)}
            <Link to="/photo-gallery" className="mr-2">
                <button type="button" className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Photo Gallery</button>
            </Link>
        </div>
        </div>
    );
};

export default CardView;