import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {
    FaFacebook,
    FaInstagram,
    FaYoutube,
    FaTwitter,
    FaLinkedin
} from 'react-icons/fa';
import { FaPrayingHands } from 'react-icons/fa';

function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>About Us</h2>
                        <Link to='/about-us'>Meet our Team</Link>
                        <Link to='/about-us'>What we do</Link>
                    </div>
                        <div className='footer-link-items'>
                            <h2>Contact Us</h2>
                            <Link to='/'>Via Social Media</Link>
                            <a href="tel:+16477192666">Tel:+16477192666</a>
                        </div>
                    </div>
                    <div className='footer-link-wrapper'>
                        <div className='footer-link-items'>
                            <h2>Social</h2>
                            <Link to='/'>Instagram</Link>
                            <Link to={'//www.facebook.com/Christy-Ihunaegbo-Foundation-104855881722696/'}
                            target='_blank'
                            aria-label='Facebook'
                            >Facebook</Link>

                        </div>
                        <div className='footer-link-items'>
                            <h2>Media</h2>
                            <Link to={'//www.youtube.com/channel/UC50r8J5k3Iie-EtDf_aqWMg?view_as=subscriber'}
                            target='_blank'
                            aria-label='Youtube'
                            >Youtube</Link>
                            <Link to='/'>Twitter</Link>
                        </div>
                    </div>
            </div>
            <section className='social-media'>
                <div className='social-media-wrap'>
                <div className='footer-logo'>
                    <Link to='/' className='social-logo'>
                    <FaPrayingHands className='navbar-icon' />
                    Christy Ihunaegbo Foundation
                    </Link>
                </div>
                <small className='website-rights'>© 2021</small>
                <div className='social-icons'>
                    <Link
                    className='social-icon-link'
                    to={'//www.facebook.com/Christy-Ihunaegbo-Foundation-104855881722696/'}
                    target='_blank'
                    aria-label='Facebook'
                    >
                    <FaFacebook />
                    </Link>
                    <Link
                    className='social-icon-link'
                    to='/'
                    target='_blank'
                    aria-label='Instagram'
                    >
                    <FaInstagram />
                    </Link>
                    <Link
                    className='social-icon-link'
                    to={
                        '//www.youtube.com/channel/UC50r8J5k3Iie-EtDf_aqWMg?view_as=subscriber'
                    }
                    target='_blank'
                    aria-label='Youtube'
                    >
                    <FaYoutube />
                    </Link>
                    <Link
                    className='social-icon-link'
                    to='/'
                    target='_blank'
                    aria-label='Twitter'
                    >
                    <FaTwitter />
                    </Link>
                    <Link
                    className='social-icon-link'
                    to='/'
                    target='_blank'
                    aria-label='LinkedIn'
                    >
                    <FaLinkedin />
                    </Link>
                </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;