import React from 'react';
import Layout from '../Layout';


const VideoGallery = () => {
    return (
        <Layout>
            <div class="jumbotron jumbotron-fluid">
            <div class="container">
                <h1 class="display-4">Events Video Gallery</h1>
                <p className='lead m-1 p-1'>
                    Under Maintanance, check back later
                </p>
            </div>
            </div>
        </Layout>
    )
}

export default VideoGallery;