import React, {Fragment ,useState, useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from '../component/Button';
import './Menu.css';
//import { FaPrayingHands } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { isAuth, signout } from '../auth/helpers';
import stflogo from '../img/newLogo.jpg';


const isActive = (match, path) => {
    if (match.path === path) {
        return { color: '#fff' };
    } else {
        return { color: '#000' };
    }
};

const Menu = ({history}) => {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };


    useEffect(() => {
        showButton();
        window.removeEventListener('resize', showButton)
    }, [])
    

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <nav className="navbar">
                <div className="navbar-container my-container">    
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src={stflogo} alt="Logo" className='navbar-icon' />Christy Ihunaegbo Foundation         
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to="/" className="nav-links" style={isActive('/')}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-us" className="nav-links" style={isActive('/about-us')}>
                                About-us
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/track-club" className="nav-links" style={isActive('/track-club')}>
                                Track-club
                            </Link>
                        </li>

                        {!isAuth() && (
                            <Fragment>
                            <li className="nav-item">
                                <Link 
                                    to="/signin" 
                                    className="nav-links" 
                                    style={isActive('/signin')} 
                                    onClick={closeMobileMenu}
                                >
                                    Signin
                                </Link>
                            </li>
                            <li className="nav-item">
                                {button ? ( 
                                <Link 
                                    to="/signup" 
                                    className="nav-links" 
                                    style={isActive('/signup')}
                                    onClick={closeMobileMenu}
                                >
                                    <Button buttonSize='btn--small' buttonStyle='btn--outline'>Signup</Button>
                                </Link>
                                ) : (
                                <Link 
                                    to="/signup" 
                                    className="nav-links" 
                                    style={isActive(history, '/signup')}
                                    onClick={closeMobileMenu}
                                >
                                    <Button buttonStyle='btn--outline' buttonSize='btn--mobile'>
                                        Signup
                                    </Button>
                                </Link>
                                )
                                }
                            </li>
                            </Fragment>
                        )}

                        {isAuth() && isAuth().role === 'admin' && (
                            <li className="nav-item">
                                <Link className="nav-links" style={isActive('/admin')} to="/admin">
                                    {isAuth().name}
                                </Link>
                            </li>
                        )}

                        {isAuth() && isAuth().role === 'subscriber' && (
                            <li className="nav-item">
                                <Link className="nav-links" style={isActive('/private')} to="/private">
                                    {isAuth().name}
                                </Link>
                            </li>
                        )}

                        {isAuth() && (
                            <li className="nav-item">
                                <span
                                    className="nav-links"
                                    style={{ cursor: 'pointer', color: '#fff' }}
                                    onClick={() => {
                                        signout(() => {
                                            history.push('/');
                                        });
                                    }}
                                >
                                    Signout
                                </span>
                            </li>
                        )}

                        <li className="nav-item">
                            <Link to="/donate" className="nav-links" style={isActive('/donate')}>
                                <Button buttonSize='btn--small' buttonStyle='btn--outline-orange'>Donate</Button>
                            </Link>
                        </li>
                    </ul>
                </div>
        </nav>
            </IconContext.Provider>
        </>
    )
};

export default withRouter(Menu);